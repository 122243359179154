import * as React from "react";
import logo from "../Assets/Images/logo.png"; //'/../Assets/Images/project_icon.png';

export default function ItemView({ item }) {
  const url = "/project/" + item.ProjectId + "/experiment/" + item.Id;

  return (
    <a href={url}>
      <li class="flex flex-row">
        <div
          maxWidth
          class="select-none cursor-pointer hover:bg-gray-50 flex flex-1 items-center p-4"
        >
          <div class="flex flex-col w-10 h-10 justify-center items-center mr-4">
            <img
              alt="profil"
              src={logo}
              class="mx-auto object-cover rounded-2 h-10 w-10"
            />
          </div>

          <div class="flex-auto flex-col">
            <div class="font-medium dark:text-white text-sm">{item.Name}</div>
            <div class="text-gray-600 dark:text-gray-200 text-sm">
              Experiment
            </div>
          </div>

          <div class="flex-auto flex-col">
            <div class="font-medium text-sm text-center">Conversion</div>
            <div class="text-gray-600 text-sm text-rose-800 text-center">
              --
            </div>
          </div>

          <div class="flex-none mx-3">
            <div class="text-sm">Settings</div>
            <div class="text-gray-600 dark:text-gray-200 text-sm">
              <a href={url + "/edit"}>Edit</a>
            </div>
          </div>
        </div>
      </li>
    </a>
  );
}
